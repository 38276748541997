<template>
  <div class="d-flex flex-column">
    Click "Simulate Scan" to see the effect of this setting on the message that
    appears when a QR code is scanned.<br />
    <v-switch class="ml-3" v-model="revealOnScan" label="Reveal Part on Scan" />
    <small
      >Each QR code has an index number that represents a piece of the message.
      When a QR code is scanned, should that piece of the message be shown, or
      just the index number?</small
    >
    <!-- <small
      >When a QR code is scanned, there are two parts: (1) an index number, and
      (2) a phrase from the message associated with that index number. If this
      option is "on", then both the index number <i>and</i> phrase will be shown
      when the QR is scanned. If this option is off, only the index number will
      be shown to the user.</small
    > -->
    <v-spacer />
    <center>
      <v-btn @click="tryOptions" color="info" class="mb-1">Simulate Scan</v-btn>
    </center>
    <v-spacer v-for="i in spacerCount" :key="i" />
    <scan-snackbar
      v-model="snackbarVisible"
      :readLetter="false"
      :revealOnScan="revealOnScan"
      :hasRevealed="wasRevealed"
      :letterPortion="dividedLetter[toReveal]"
      :letterIndex="toReveal + 1"
    />
  </div>
</template>

<script>
import scanSnackbar from "@/components/scanSnackbar.vue";

export default {
  name: "RevealOnScanStep",
  props: ["dividedLetter", "runCalcs"],
  data() {
    return {
      revealOnScanRaw: false,
      toReveal: 0,
      hasRevealed: false,
      wasRevealed: false,
      snackbarVisible: false,
    };
  },
  computed: {
    revealOnScan: {
      get() {
        return this.revealOnScanRaw;
      },
      set(val) {
        this.snackbarVisible = false;
        this.revealOnScanRaw = val;
        this.hasRevealed = false;
        this.$emit("update:revealOnScan", this.revealOnScanRaw);
      },
    },
    spacerCount() {
      return Math.max(
        Math.ceil((this.$store.getters.windowSize.height - 700) / 100),
        0
      );
    },
  },
  methods: {
    tryOptions() {
      this.wasRevealed = this.hasRevealed;
      this.snackbarVisible = "true" + new Date().getTime() + Math.random();
      this.hasRevealed = true;
    },
  },
  watch: {
    runCalcs(newVal) {
      if (newVal) {
        this.revealOnScanRaw = this.$attrs.revealOnScan;
        this.toReveal = parseInt(Math.random() * this.dividedLetter.length);
        this.hasRevealed = false;
      }
    },
  },
  components: {
    scanSnackbar,
  },
};
</script>
