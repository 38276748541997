<template>
  <div>
    Please enter your email address. When registration is complete, you will
    receive an email with login information.
    <v-text-field
      v-model="emailModel"
      label="Email Address"
      :rules="[emailRule]"
    />
  </div>
</template>

<script>
export default {
  name: "EmailStep",
  props: ["email"],
  methods: {
    emailRule(v) {
      return (
        this.$store.getters.emailRegex.test(v) ||
        "Please enter a valid email address."
      );
    },
  },
  computed: {
    emailModel: {
      get() {
        return this.email;
      },
      set(newVal) {
        this.$emit("update:email", newVal);
      },
    },
  },
};
</script>
