<template>
  <div>
    On what date should the QR scanning begin? (Used for displaying
    statistics)<br /><br />
    <v-date-picker
      v-model="datePickerVal"
      :min="minDate"
      :max="maxDate"
      style="max-width: 600px; margin-left: 6px"
      elevation="4"
    /><br /><br />
    <span
      >The message will be fully uncovered on
      <strong>{{ $store.getters.dateString(endDate) }}</strong
      >.</span
    >
  </div>
</template>

<script>
/*

      :style="
        'max-width: 600px; ' +
        ($vuetify.breakpoint.xsOnly ? 'width: 200px; margin-left: 6px;' : '')
      "

            :class="$vuetify.breakpoint.smAndUp ? 'mx-3' : ''"
      :width="$vuetify.breakpoint.xsOnly ? '200' : ''"

      */
export default {
  name: "StartDateStep",
  props: ["dividedLetter", "scansPerDay"],
  data() {
    return {
      startDateRaw: new Date(),
    };
  },
  computed: {
    divisionCount() {
      return this.dividedLetter.length;
    },
    daysToComplete() {
      return Math.ceil(this.divisionCount / this.scansPerDay);
    },
    datePickerVal: {
      get() {
        return this.startDate.toISOString().substring(0, 10);
      },
      set(v) {
        let [year, month, date] = v.split("-");
        year = Number(year);
        month = Number(month);
        month--;
        date = Number(date);
        this.startDateRaw = new Date(year, month, date);
      },
    },
    startDate() {
      let d = this.startDateRaw;
      let returnVal = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      this.$emit("update:startDate", returnVal);
      return returnVal;
    },
    minDate() {
      let d = new Date();
      return new Date(d.getFullYear(), d.getMonth(), d.getDate() - 30)
        .toISOString()
        .substring(0, 10);
    },
    maxDate() {
      let d = new Date();
      return new Date(d.getFullYear(), d.getMonth(), d.getDate() + 30)
        .toISOString()
        .substring(0, 10);
    },
    endDate() {
      let d = this.startDate;
      let returnVal = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() + this.daysToComplete - 1
      );
      this.$emit("update:endDate", returnVal);
      return returnVal;
    },
  },
};
</script>
