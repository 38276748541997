<template>
  <div>
    <v-text-field
      v-model="rawScansPerDay"
      :label="`How many scans should be allowed each day? (1 to ${divisionCount})`"
      :rules="[
        (v) => !!v || 'Must have a value.',
        (v) =>
          (Number(v) <= divisionCount && Number(v) >= 1) ||
          'Must be in defined range.',
      ]"
    /><br />
    <span
      >It will take <strong>{{ daysToComplete }}</strong> day{{
        daysToComplete == 1 ? "" : "s"
      }}
      to uncover the whole message.</span
    >
  </div>
</template>

<script>
export default {
  name: "ScansPerDayStep",
  props: ["dividedLetter"],
  data() {
    return {
      rawScansPerDay: "1",
      lastValidScans: 1,
    };
  },
  computed: {
    divisionCount() {
      return this.dividedLetter.length;
    },
    daysToComplete() {
      let output = Math.ceil(this.divisionCount / this.scansPerDay);
      return !Number.isFinite(output) ? "?" : output;
    },
    scansPerDay() {
      let num = Number(this.rawScansPerDay);
      // if (num <= this.divisionCount && num >= 1) {
      if (!Number.isNaN(num)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.lastValidScans = num;
      }
      this.$emit("update:scansPerDay", this.lastValidScans);
      return this.lastValidScans;
    },
  },
};
</script>
