<template>
  <div class="d-flex flex-column align-stretch">
    <v-card
      color="white"
      elevation="5"
      rounded="lg"
      style="
        cursor: pointer;
        border-width: 0.3vmax;
        border-style: solid;
        border-color: #aaa !important;
      "
      class="d-flex flex-column flex-grow-1"
    >
      <v-stepper
        v-model="instructionSteps"
        height="100%"
        class="
          align-self-stretch
          flex-grow-1
          d-flex
          flex-column
          pa-3
          custom-stepper
        "
        :class="{ 'custom-stepper-alt': $vuetify.breakpoint.smAndDown }"
        alt-labels
      >
        <center class="d-flex align-center justify-center">
          <v-btn
            :style="`opacity: ${instructionSteps > 1 ? '1' : '0'};`"
            :disabled="instructionSteps <= 1"
            @click="instructionSteps--"
            fab
            small
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <h3 style="display: inline">
            {{ stepText }}
          </h3>
          <v-spacer />
          <v-btn
            :style="`opacity: ${instructionSteps >= steps.length ? '0' : '1'};`"
            :disabled="instructionSteps >= steps.length"
            @click="nextStep"
            fab
            small
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </center>
        <v-stepper-content step="1" class="pa-3">
          <letter-step :letter.sync="letter" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <divide-step
            :startDate.sync="startDate"
            :endDate.sync="endDate"
            :scansPerDay.sync="scansPerDay"
            :dividedLetter.sync="dividedLetter"
            :letter="letter"
            :runCalcs="instructionSteps > 1"
            :divisionGood.sync="divisionGood"
          />
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-3">
          <scans-per-day-step
            :dividedLetter="dividedLetter"
            :scansPerDay.sync="scansPerDay"
          />
        </v-stepper-content>

        <v-stepper-content step="4" class="pa-3">
          <start-date-step
            :dividedLetter="dividedLetter"
            :scansPerDay="scansPerDay"
            :startDate.sync="startDate"
            :endDate.sync="endDate"
          />
        </v-stepper-content>

        <v-stepper-content step="5" class="pa-3">
          <reveal-on-scan-step
            :dividedLetter="dividedLetter"
            :revealOnScan.sync="revealOnScan"
            :runCalcs="instructionSteps > 4"
          />
        </v-stepper-content>

        <v-stepper-content step="6" class="pa-3">
          <read-letter-step
            :dividedLetter="dividedLetter"
            :revealOnScan="revealOnScan"
            :readLetter.sync="readLetter"
            :runCalcs="instructionSteps > 5"
          />
        </v-stepper-content>

        <v-stepper-content step="7" class="pa-3">
          <email-step :email.sync="email" />
        </v-stepper-content>

        <v-stepper-content step="8" class="pa-3">
          <email-confirm-step
            :email="email"
            :emailConfirm.sync="emailConfirm"
            ref="emailConfirm"
          />
        </v-stepper-content>

        <v-stepper-content step="9" class="pa-3">
          <print-labels-step
            :printLabels.sync="printLabels"
            :startDate="startDate"
          />
        </v-stepper-content>

        <v-stepper-content step="10" class="pa-3">
          <pay-step
            :dividedLetter="dividedLetter"
            :scansPerDay="scansPerDay"
            :startDate="startDate"
            :endDate="endDate"
            :revealOnScan="revealOnScan"
            :readLetter="readLetter"
            :email="email"
            :printLabels="printLabels"
          />
        </v-stepper-content>

        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :key="index"
              :step="index + 1"
              :complete="instructionSteps > index + 1"
              :rules="stepperRules[index]"
              @click="goToStep(index)"
            >
              {{ step }}
            </v-stepper-step>
            <v-divider
              v-if="index < steps.length - 1"
              :key="`${index}divider`"
              :style="`flex-basis: ${
                index == longDivide ? '100%' : '0'
              } !important; margin-left: ${divideMargin}; margin-right: ${divideMargin}; margin-top: ${
                index == longDivide ? '0px; opacity: 0;' : '21px;'
              }`"
            />
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-card>
    <v-snackbar timeout="-1" v-model="showSnackbar" color="error">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style>
.custom-stepper {
  height: auto !important;
}

.custom-stepper .v-stepper__header {
  box-shadow: none !important;
}

.custom-stepper .v-stepper__header .v-stepper__step {
  flex-basis: 0 !important;
  flex-grow: 1;
  padding: 10px !important;
}

.custom-stepper .v-stepper__header .v-stepper__step .v-stepper__label {
  text-align: center;
}

.custom-stepper-alt .v-stepper__header .v-stepper__step__step {
  margin-bottom: 0px !important;
}

.custom-stepper .v-stepper__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.custom-stepper .v-stepper__content .v-stepper__wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.custom-stepper .v-stepper__content > .v-stepper__wrapper > * {
  flex-grow: 1;
}
</style>

<script>
import letterStep from "@/components/letterStep.vue";
import divideStep from "@/components/divideStep.vue";
import scansPerDayStep from "@/components/scansPerDayStep.vue";
import startDateStep from "@/components/startDateStep.vue";
import revealOnScanStep from "@/components/revealOnScanStep.vue";
import readLetterStep from "@/components/readLetterStep.vue";
import emailStep from "@/components/emailStep.vue";
import emailConfirmStep from "@/components/emailConfirmStep.vue";
import printLabelsStep from "@/components/printLabelsStep.vue";
import payStep from "@/components/payStep.vue";

export default {
  name: "GetStarted",
  data() {
    return {
      instructionSteps: 1,
      letter: "",
      dividedLetter: [],
      divisionGood: true,
      scansPerDay: 1,
      startDate: new Date(),
      endDate: new Date(),
      revealOnScan: true,
      readLetter: true,
      email: "",
      emailConfirm: "",
      printLabels: false,
      snackbarText: "",
      showSnackbar: false,
      steps: [
        "Write Message",
        "Divide Message",
        "Scans Per Day",
        "Choose Start",
        "Settings (1/2)",
        "Settings (2/2)",
        "Email",
        "Email (Again)",
        "Label Printing",
        "Confirm / Pay",
      ],
      stepperRules: [],
      haveError: false,
      errWatchers: [],
    };
  },
  computed: {
    stepText() {
      return `Step ${this.instructionSteps}: ${
        this.steps[this.instructionSteps - 1]
      }`;
    },
    longDivide() {
      if (this.$store.getters.windowSize.width <= 490) {
        return 4;
      } else {
        return -1;
      }
    },
    stepRules() {
      return {
        "Write Message": [
          () =>
            this.letter.length > 0 || "You must enter a message to move on.",
        ],
        "Divide Message": [
          () => !!this.divisionGood || "Please correct all errors.",
        ],
        "Scans Per Day": [
          () =>
            (this.scansPerDay <= this.dividedLetter.length &&
              this.scansPerDay >= 1) ||
            `Scans per day must be in range 1 to ${this.dividedLetter.length}.`,
        ],
        "Choose Start": [],
        "Settings (1/2)": [],
        "Settings (2/2)": [],
        Email: [
          () =>
            this.$store.getters.emailRegex.test(this.email) ||
            "Please enter a valid email address.",
        ],
        "Email (Again)": [
          () =>
            this.email == this.emailConfirm ||
            "The email addresses don't match.",
        ],
        "Label Printing": [],
        "Confirm / Pay": [],
      };
    },
    errFixers() {
      return {
        letter: (v) => !!v,
        email: (v) => this.$store.getters.emailRegex.test(v),
        emailConfirm: (v) => this.email == v,
        divisionGood: (v) => !!v,
        scansPerDay: this.stepRules["Scans Per Day"],
      };
    },
    divideMargin() {
      // with like 2 items we would want like -15 px
      // with 6 we want like -7
      // But it also depends on the breakpoint.
      return `calc(((100vw - 54px - 42px * ${this.steps.length}) / ${this.steps.length}) / (0 - 2))`;
    },
  },
  methods: {
    async goToStep(index) {
      let self = this;
      index++;
      if (index < self.instructionSteps) {
        self.instructionSteps = index;
      } else {
        while (self.instructionSteps < index) {
          let res;
          if (self.instructionSteps == 2) {
            // this is "run calcs" break for many things, so we want to give a tick for various computed properties to update.
            await new Promise((resolve) => {
              self.$nextTick(() => {
                res = self.nextStep();
                resolve();
              });
            });
          } else {
            res = self.nextStep();
          }
          if (!res) {
            break;
          }
        }
      }
    },
    nextStep() {
      let res = this.evaluateRules(this.instructionSteps - 1);
      if (res === true) {
        this.instructionSteps++;
        this.showSnackbar = false;
        return true;
      } else {
        this.snackbarText = res;
        this.showSnackbar = true;
        return false;
      }
    },
    evaluateRules(step) {
      let res = true;
      for (let rule of this.stepRules[this.steps[step]]) {
        res = res && rule();
        if (res === true) {
          //continue on
        } else if (res === false) {
          //return error
          res = "Error";
          this.haveError = true;
          break;
        } else {
          this.haveError = true;
          break;
        }
      }
      this.stepperRules[step] = [() => res];
      return res;
    },
    resetErrors() {
      this.stepperRules = [];
      for (let i = 0; i < this.steps.length; i++) {
        this.stepperRules.push([() => true]);
      }
      this.showSnackbar = false;
      this.haveError = false;
    },
    removeAllWatchers() {
      for (let i = this.errWatchers.length - 1; i >= 0; i--) {
        this.errWatchers[i]();
        this.errWatchers.splice(i, 1);
      }
    },
    addAllWatchers() {
      let self = this;
      for (let fixer in self.errFixers) {
        self.errWatchers.push(
          self.$watch(fixer, (v) => {
            let allPass = true;
            let ruleArr = self.errFixers[fixer];
            if (typeof ruleArr == "function") {
              ruleArr = [ruleArr];
            }
            for (let rule of ruleArr) {
              allPass = allPass && rule(v);
              if (!allPass) {
                break;
              }
            }
            if (allPass) {
              self.resetErrors();
            }
          })
        );
      }
    },
  },
  async mounted() {
    await Promise.all([
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        if (!this.$store.hasModule("awsGuest")) {
          let awsGuest = (await import("@/store/awsGuest.js")).default;
          this.$store.registerModule("awsGuest", awsGuest);
        }
        resolve();
      }),
    ]);
    this.resetErrors();
  },
  async beforeDestroy() {
    await self.$store.dispatch("awsGuest/resetStore");
  },
  watch: {
    haveError(err) {
      if (err) {
        this.addAllWatchers();
      } else {
        this.removeAllWatchers();
      }
    },
    email() {
      try {
        this.$refs.emailConfirm.$refs.input.resetValidation();
      } catch (err) {
        // don't care
      }
    },
  },
  components: {
    letterStep,
    divideStep,
    scansPerDayStep,
    startDateStep,
    revealOnScanStep,
    readLetterStep,
    emailStep,
    emailConfirmStep,
    printLabelsStep,
    payStep,
  },
};
</script>
