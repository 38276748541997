<template>
  <div class="d-flex flex-column">
    Click "Simulate Scan" to see the effect of this setting on the message that
    appears when a QR code is scanned.<br />
    <v-switch class="ml-3" v-model="readLetter" label="Read Partial Letter" />
    <small
      >Should a 'Read Letter' button appear? It will let the user see all the
      parts of the message they have uncovered so far. If not, they'll only be
      able to read the message when it's 100% uncovered.</small
    >
    <!-- <small
      >At any point during the message-uncovering process, some of the message
      has been "discovered" (the associated QR code has been scanned), and some
      of the message is still concealed. If this message is on, the user can
      view the letter at any point: uncovered parts will appear as normal, and
      concealed parts will be covered with "_" and unreadable.</small
    ><br /><br /> -->
    <v-spacer />
    <center>
      <v-btn @click="tryOptions" color="info" class="mb-1">Simulate Scan</v-btn>
    </center>
    <v-spacer v-for="i in spacerCount" :key="i" />
    <scan-snackbar
      v-model="snackbarVisible"
      :readLetter="readLetter"
      @read-letter="showLetter = true"
      :revealOnScan="revealOnScan"
      :hasRevealed="wasRevealed"
      :letterPortion="dividedLetter[toReveal]"
      :letterIndex="toReveal + 1"
    />
    <v-dialog v-model="showLetter">
      <v-card class="pa-3">
        <v-card-text class="pa-0">
          {{ letter }}
        </v-card-text>
        <v-card-actions>
          <v-spacer /><v-btn @click="showLetter = false" color="error"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import scanSnackbar from "@/components/scanSnackbar.vue";

export default {
  name: "ReadLetterStep",
  props: ["dividedLetter", "runCalcs", "revealOnScan"],
  data() {
    return {
      readLetterRaw: false,
      snackbarVisible: false,
      toReveal: 0,
      hasRevealed: false,
      wasRevealed: false,
      showLetter: false,
    };
  },
  computed: {
    readLetter: {
      get() {
        return this.readLetterRaw;
      },
      set(val) {
        this.snackbarVisible = false;
        this.readLetterRaw = val;
        this.hasRevealed = false;
        this.$emit("update:readLetter", this.readLetterRaw);
      },
    },
    letter() {
      let letterPartsCopy = JSON.parse(JSON.stringify(this.dividedLetter));
      for (let i = 0; i < letterPartsCopy.length; i++) {
        if (this.toReveal != i) {
          letterPartsCopy[i] = letterPartsCopy[i].replace(/./gi, "_");
        }
      }
      return letterPartsCopy.join("");
    },
    spacerCount() {
      return Math.max(
        Math.ceil((this.$store.getters.windowSize.height - 700) / 100),
        0
      );
    },
  },
  methods: {
    tryOptions() {
      this.wasRevealed = this.hasRevealed;
      this.snackbarVisible = "true" + new Date().getTime() + Math.random();
      this.hasRevealed = true;
    },
  },
  watch: {
    runCalcs(newVal) {
      if (newVal) {
        this.readLetterRaw = this.$attrs.readLetter;
        this.toReveal = parseInt(Math.random() * this.dividedLetter.length);
        this.hasRevealed = false;
      }
    },
  },
  components: {
    scanSnackbar,
  },
};
</script>
