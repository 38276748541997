<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Item</th>
            <th v-if="wideEnough" class="text-left">Price per</th>
            <th v-if="wideEnough" class="text-left">Quantity</th>
            <th class="text-left">Minimum</th>
            <th class="text-left">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in priceBreak" :key="index">
            <td v-html="item.name" />
            <td v-if="wideEnough">{{ item.pricePer }}</td>
            <td v-if="wideEnough">{{ item.quantity }}</td>
            <td>{{ item.minPrice }}</td>
            <td v-html="item.price" />
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <small>
      *Subject to change based on shipping address used in payment: $1.50 US,
      $3.00 International
    </small>
    <br /><br />
    <!-- <center>
      <v-btn @click="infoDialog = true" color="info"
        >What Happens Next?&nbsp;
        <v-icon color="white">mdi-information-outline</v-icon></v-btn
      >
    </center> -->
    <v-dialog v-model="infoDialog">
      <v-card class="pa-0 pt-6 pb-2">
        <v-card-text>
          <span>After paying, two things will happen: </span><br />
          <ol>
            <li>
              You will receive a link in your email to log in to your account.
            </li>
            <li>
              You will be automatically logged in - you may want to bookmark the
              page (in case your email was entered incorrectly and you don't
              receive the login information).
            </li>
          </ol>
          <br />
          <span
            >When you're logged in, you will be given instruction to print the
            QR codes (or be able to see the status of the printing and
            shipping), as well as instructions for scanning QR codes.</span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="infoDialog = false" color="info">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div :id="renderId" :style="infoDialog ? 'pointer-events: none;' : ''" />
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";

export default {
  name: "PayStep",
  props: [
    "dividedLetter",
    "printLabels",
    "startDate",
    "endDate",
    "scansPerDay",
    "revealOnScan",
    "readLetter",
    "email",
  ],
  data() {
    return {
      internationalShipping: false,
      paypalObj: null,
      renderId: "paypalHolder",
      infoDialog: false,
    };
  },
  computed: {
    wideEnough() {
      return this.$store.getters.windowSize.width > 490;
      // return this.$vuetify.breakpoint.smAndUp;
    },
    priceBreak() {
      let output = [];
      let qrName = "QR Code";
      if (!this.wideEnough) {
        qrName = `QR Code<br /><small style="white-space:nowrap;">($0.01/QR * ${
          this.dividedLetter.length
        } Code${this.dividedLetter.length == 1 ? "" : "s"})</small>`;
      }
      output.push({
        name: qrName,
        pricePer: "$0.01",
        quantity: this.dividedLetter.length,
        minPrice: "$5.00",
        price: this.formatMoney(this.qrPrice),
      });
      if (this.printLabels) {
        let labelName = "Label Printing";
        if (!this.wideEnough) {
          labelName = `Label Printing<br /><small style="white-space:nowrap;">($0.01/QR * ${
            this.dividedLetter.length
          } Code${this.dividedLetter.length == 1 ? "" : "s"})</small>`;
        }
        output.push({
          name: labelName,
          pricePer: "$0.01",
          quantity: this.dividedLetter.length,
          minPrice: "$1.00",
          price: this.formatMoney(this.printPrice),
        });
        let shippingName = "Domestic Shipping";
        if (this.internationalShipping) {
          shippingName = "International Shipping";
        }
        output.push({
          name: `${shippingName}*`,
          pricePer: "",
          quantity: "",
          minPrice: "",
          price: this.formatMoney(this.shippingPrice),
        });
      }
      output.push({
        name: "<b>Total</b>",
        pricePer: "",
        quantity: "",
        minPrice: "",
        price: `<b>${this.formatMoney(this.paymentAmount)}</b>`,
      });
      return output;
    },
    paymentAmount() {
      let rawAmount = this.nonShippingPrice + this.shippingPrice;
      return parseFloat(rawAmount.toFixed(2));
    },
    nonShippingPrice() {
      let rawAmount = this.qrPrice + this.printPrice;
      return parseFloat(rawAmount.toFixed(2));
    },
    shippingPrice() {
      return this.printLabels ? (this.internationalShipping ? 3 : 1.5) : 0;
    },
    qrPrice() {
      return Math.max(this.dividedLetter.length * 0.01, 5);
    },
    printPrice() {
      return this.printLabels
        ? Math.max(this.dividedLetter.length * 0.01, 1)
        : 0;
    },
    fundingSources() {
      if (this.paypalObj != null) {
        return [
          this.paypalObj.FUNDING.PAYPAL,
          this.paypalObj.FUNDING.VENMO,
          // this.paypalObj.FUNDING.PAYLATER,
          // this.paypalObj.FUNDING.CREDIT,
          this.paypalObj.FUNDING.CARD,
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    formatMoney(amount) {
      return `$${amount.toFixed(2)}`;
    },
    onShippingChange(data, actions) {
      let self = this;
      if (self.printLabels) {
        let didChange = false;
        if (data.shipping_address.country_code === "US") {
          if (self.internationalShipping) {
            self.internationalShipping = false;
            didChange = true;
          }
        } else {
          if (!self.internationalShipping) {
            self.internationalShipping = true;
            didChange = true;
          }
        }
        if (!didChange) {
          return actions.resolve();
        }
        return actions.order.patch([
          {
            op: "replace",
            path: "/purchase_units/@reference_id=='default'/amount",
            value: {
              currency_code: "USD",
              value: self.paymentAmount.toFixed(2),
              breakdown: {
                item_total: {
                  currency_code: "USD",
                  value: self.nonShippingPrice.toFixed(2),
                },
                shipping: {
                  currency_code: "USD",
                  value: self.shippingPrice.toFixed(2),
                },
              },
            },
          },
        ]);
      } else {
        return actions.resolve();
      }
    },
    createOrder(data, actions) {
      let self = this;
      return self.$store.dispatch("getTempID").then((tempId) => {
        return actions.order
          .create({
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: self.paymentAmount,
                },
                custom_id: `${this.email}@${tempId}`,
              },
            ],
          })
          .then((response) => {
            return response;
          });
      });
    },
    // onApprove(data, actions) {
    async onApprove(data) {
      let self = this;
      self.$store.commit("addLoading", "Processing Payment");
      try {
        let tempId = await self.$store.dispatch("getTempID");
        await self.$store.dispatch("awsGuest/saveS3Files", {
          id: tempId,
          files: [
            {
              name: "letter",
              data: self.dividedLetter,
            },
            {
              name: "settings",
              data: {
                startDate: "" + self.startDate.getTime(),
                endDate: "" + self.endDate.getTime(),
                scansPerDay: "" + self.scansPerDay,
                revealOnScan: self.revealOnScan ? "1" : "0",
                readLetter: self.readLetter ? "1" : "0",
                printLabels: self.printLabels,
                internationalShipping: self.internationalShipping,
              },
            },
          ],
        });
        let interlaced = await self.$store.dispatch(
          "capturePayment",
          data.orderID
        );
        interlaced = encodeURIComponent(interlaced);
        self.$router.push(`/admin/${interlaced}`);
      } catch (err) {
        let errTypeMap = {
          "Authentication Failure":
            "The payment could not be processed (err 0).  Your account has not been charged; please try again.  If this error persists, please contact us at pfyt.xyz@gmail.com.",
          "Capture Failure":
            "The payment could not be processed (err 1).  Your account has not been charged; please try again.  If this error persists, please contact us at pfyt.xyz@gmail.com.",
          "Payment incomplete":
            "The payment could not be processed (err 2).  Your account has not been charged; please try again.  If this error persists, please contact us at pfyt.xyz@gmail.com.",
          "Pre-Capture Payment Mismatch":
            "The payment amount does not match the calculated value.  Your account has not been charged, but funds may be on hold.  If this error persists, please contact us at pfyt.xyz@gmail.com.",
          "Paypal Verify Failure":
            "The funds were not approved for purchase.  If this error persists, please contact us at pfyt.xyz@gmail.com.",
          "Pre-Capture Identification Mismatch":
            "The message received by Paypal did not align with the data passed to the server.  Your account has not been charged, but funds may be on hold.  If this error persists, please contact us as pfyt.xyz@gmail.com",
          "Capture Error":
            "The payment could not be processed (err 3).  Your account should not be charged; please try again.  If this error persists, please contact us at pfyt.xyz@gmail.com.",
          "Identification Mismatch":
            "The payment did not align with the message the server received.  Please try again and contact us at pfyt.xyz@gmail.com if the error persists.",
          "Payment Mismatch":
            "The amount paid did not match the calculated payment.  Please contact us at pfyt.xyz@gmail.com.",
          "Missing Files":
            "Unable to load requested data.  Your payment was not processed, but funds may be on hold.  If the error persists, please contact us at pfyt.xyz@gmail.com.",
          "Cognito Account Creation Error":
            "Your account could not be created. Since your payment has been charged, please contact support at pfyt.xyz@gmail.com for a refund.",
          "Save Files Error":
            "The configuration for your account could not be saved.  Since the payment has been processed, please contact us at pfyt.xyz@gmail.com.",
          "Delete Files Error":
            "The configuration files could not be cleaned.  Since your payment has been processed, please contact us at pfyt.xyz@gmail.com.",
        };
        if (
          Object.prototype.hasOwnProperty.call(err, "errType") &&
          Object.prototype.hasOwnProperty.call(errTypeMap, err.errType)
        ) {
          self.$store.commit("addError", errTypeMap[err.errType]);
        } else {
          // craaaap
          self.$store.commit("removeLoading", "Processing Payment");
          console.error(err);
          throw err;
        }
      }
      self.$store.commit("removeLoading", "Processing Payment");
    },
  },
  mounted() {
    let self = this;
    loadScript({
      "client-id":
        "Aef6xbIUxN08vqK6VJvKL5FKBCHwTnt01IYhqZ6sz_4V5hX3DluLBKF9kM34mShNxiD4y3iLQSt2xsS6",
      commit: true,
      components: "buttons,funding-eligibility,marks",
      intent: "capture",
    })
      .then((paypal) => {
        self.paypalObj = paypal;
      })
      .catch((err) => {
        console.error("failed to load the PayPal JS SDK script", err);
      });
  },
  watch: {
    paypalObj(newVal) {
      let self = this;
      if (newVal != null) {
        document.getElementById(self.renderId).innerHTML = "";
        for (let fundingSource of self.fundingSources) {
          let mark = self.paypalObj.Marks({
            fundingSource: fundingSource,
          });

          // Check if the mark is eligible
          if (mark.isEligible()) {
            self.paypalObj
              .Buttons({
                onShippingChange: self.onShippingChange,
                createOrder: self.createOrder,
                onApprove: self.onApprove,
                fundingSource: fundingSource,
                style: {
                  shape: "pill",
                },
              })
              .render("#" + self.renderId);
          }
        }
      }
    },
  },
};
</script>
