<template>
  <div class="d-flex flex-column">
    <v-textarea
      v-model="rawLetter"
      outlined
      :counter="true"
      :counter-value="counterText"
      placeholder="Type your message here"
      class="flex-grow-1 custom-textarea"
    />
  </div>
</template>

<style>
.custom-textarea > * {
  height: 100% !important;
}

.custom-textarea > div > .v-input__slot {
  flex-grow: 1;
}

.custom-textarea > div > .v-text-field__details {
  flex-grow: 0;
}
</style>

<script>
export default {
  name: "LetterStep",
  data() {
    return {
      rawLetter: "",
      letter: "",
      rawTimeout: null,
      timeoutLen: 250, // this means the 'letter' only gets updated every 500ms, so the computed props shouldn't KILL everything.
    };
  },
  computed: {
    letterWordCount() {
      return this.letter.split(/\s+/gi).filter((a) => a != "").length;
    },
    letterCharCount() {
      return this.letter.length;
    },
  },
  methods: {
    counterText() {
      return `${this.letterWordCount} word${
        this.letterWordCount != 1 ? "s" : ""
      } / ${this.letterCharCount} char${this.letterCharCount != 1 ? "s" : ""}`;
    },
  },
  mounted() {},
  watch: {
    rawLetter() {
      let self = this;
      if (self.rawTimeout == null) {
        self.rawTimeout = setTimeout(() => {
          self.letter = self.rawLetter;
          self.$emit("update:letter", self.letter);
          self.rawTimeout = null;
        }, self.timeoutLen);
      }
    },
  },
};
</script>
